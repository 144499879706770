
@media only screen and (max-width: 1025px) and (orientation: portrait){
  :root {
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 32px;
    --spacing-chat-msg: 10rem;
    --spacing-sec-left-right: 4rem;
  }
  
  /* Font Sizes */
  :root {
    --font-size-xs: 0.825rem;
    --font-size-sm: 1.15rem;
    --font-size-md: 1.4rem;
    --font-size-lg: 2rem;
    --font-size-xl: 2.25rem;
    --font-size-navbar-a: 1.25rem;
  }
  
  /* Border Radius */
  :root {
    --border-radius-sm: 6px;
    --border-radius:  1rem;
    --border-radius-lg: 24px;
    --border-radius-chat-bg: 1.5rem;
    --border-radius-btn: 2.5rem;
  }

  /* others */
  :root{
    --chat-icon-size: 2.25rem;
  }

  /* box-shadow */
  :root{
    --box-shadow-primary: 0px 1px 2px rgba(0, 0, 0, 0.1),-0px -0px 2px rgba(0, 0, 0, 0.1);
    --box-shadow-secondary:0px 2px 3px rgba(0, 0, 0, 0.2);
  }
  /* Breakpoints */
  :root {
    --breakpoint-xs: 480px;
    --breakpoint-sm: 768px;
    --breakpoint-md: 992px;
    --breakpoint-lg: 1200px;
  }
  :root{
    --top-navbar-height: 68px;
  }
}
 
@media only screen and (max-width: 991px){
    /* Height */
  :root{
    --top-navbar-height: 60px;
    --navbar-short-width: 6rem;
  }
  :root {
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 32px;
    --spacing-chat-msg: 10rem;
    --spacing-sec-left-right: 2.5rem;
  }
  
  /* Font Sizes */
  :root {
    --font-size-xs: 0.625rem;
    --font-size-sm: 1rem;
    --font-size-md: 1.2rem;
    --font-size-lg: 2rem;
    --font-size-xl:2.25rem;
    --font-size-navbar-a: 1rem;
  }
  
  /* Border Radius */
  :root {
    --border-radius-sm: 6px;
    --border-radius:  12px;
    --border-radius-lg: 24px;
    --border-radius-chat-bg: 20px;
    --border-radius-btn: 30px;
  }

  /* others */
  :root{
    --chat-icon-size: 1.75rem;
  }

  /* box-shadow */
  :root{
    --box-shadow-primary: 0px 1px 2px rgba(0, 0, 0, 0.1),-0px -0px 2px rgba(0, 0, 0, 0.1);
    --box-shadow-secondary:0px 2px 3px rgba(0, 0, 0, 0.2);
  }
  /* Breakpoints */
  :root {
    --breakpoint-xs: 480px;
    --breakpoint-sm: 768px;
    --breakpoint-md: 992px;
    --breakpoint-lg: 1200px;
  }
}
@media only screen and (max-width: 768px){
  :root {
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 32px;
    --spacing-chat-msg: 10rem;
    --spacing-sec-left-right: 4rem;
  }
  
  /* Font Sizes */
  :root {
    --font-size-xs: 0.825rem;
    --font-size-sm: 1.15rem;
    --font-size-md: 1.4rem;
    --font-size-lg: 2rem;
    --font-size-xl: 2.25rem;
    --font-size-navbar-a: 1.25rem;
  }
  
  /* Border Radius */
  :root {
    --border-radius-sm: 6px;
    --border-radius:  1rem;
    --border-radius-lg: 24px;
    --border-radius-chat-bg: 1.5rem;
    --border-radius-btn: 2.5rem;
  }

  /* others */
  :root{
    --chat-icon-size: 2.25rem;
  }

  /* box-shadow */
  :root{
    --box-shadow-primary: 0px 1px 2px rgba(0, 0, 0, 0.1),-0px -0px 2px rgba(0, 0, 0, 0.1);
    --box-shadow-secondary:0px 2px 3px rgba(0, 0, 0, 0.2);
  }
  /* Breakpoints */
  :root {
    --breakpoint-xs: 480px;
    --breakpoint-sm: 768px;
    --breakpoint-md: 992px;
    --breakpoint-lg: 1200px;
  }
  :root{
    --top-navbar-height: 68px;
  }
}
@media only screen and (max-width: 480px){
  :root {
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 32px;
    --spacing-chat-msg: 1rem;
    --spacing-sec-left-right: 1rem;
  }
  
  /* Font Sizes */
  :root {
    --font-size-xs: 0.625rem;
    --font-size-sm: 0.825rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.35rem;
    --font-size-xl: 1.75rem;
    --font-size-navbar-a: 0.925rem;
  }
  
  /* Border Radius */
  :root {
    --border-radius-sm: 6px;
    --border-radius:  1rem;
    --border-radius-lg: 24px;
    --border-radius-chat-bg: 1.5rem;
    --border-radius-btn: 2.5rem;
  }

  /* others */
  :root{
    --chat-icon-size: 1.65rem;
  }

  /* box-shadow */
  :root{
    --box-shadow-primary: 0px 1px 2px rgba(0, 0, 0, 0.1),-0px -0px 2px rgba(0, 0, 0, 0.1);
    --box-shadow-secondary:0px 2px 3px rgba(0, 0, 0, 0.2);
  }
  /* Breakpoints */
  :root {
    --breakpoint-xs: 480px;
    --breakpoint-sm: 768px;
    --breakpoint-md: 992px;
    --breakpoint-lg: 1200px;
  }
  :root{
    --top-navbar-height: 56px;
  }
}