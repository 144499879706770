@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* Font families */
:root{
  --font-primary: 'Poppins', sans-serif;
  /* --font-secondary: 'Open Sans', sans-serif; */
}

/* Spacing */
:root {
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --spacing-chat-msg: 10rem;
  --spacing-sec-left-right: 6rem;
}

/* Font Sizes */
:root {
  --font-size-xs: 0.525rem;
  --font-size-sm: 0.75rem;
  --font-size-md: 0.95rem;
  --font-size-lg: 1.35rem;
  --font-size-xl: 1.85rem;
  --font-size-navbar-a: 0.8rem;
}

/* Border Radius */
:root {
  --border-radius-sm: 6px;
  --border-radius:  12px;
  --border-radius-lg: 24px;
  --border-radius-chat-bg: 1rem;
  --border-radius-btn: 2.5rem;
}
/* others */
:root{
  --chat-icon-size: 1.35rem;
}

/* box-shadow */
:root{
  --box-shadow-primary: 0px 1px 2px var(--box-shadow-primary-color),-0px -0px 2px var(--box-shadow-primary-color);
  --box-shadow-secondary:0px 2px 3px var(--box-shadow-secondary-color);
  --box-shadow-navbar-bottom: 0 3px 3px -2px var(--box-shadow-primary-color);
}
/* Breakpoints */
:root {
  --breakpoint-xs: 480px;
  --breakpoint-sm: 768px;
  --breakpoint-md: 992px;
  --breakpoint-lg: 1200px;
}
/* width */
:root{
  --navbar-width: 200px;
  --navbar-short-width: 6rem;
}

/* Height */
:root{
  --top-navbar-height: 44px;
  --navbar-short-width: 6rem;
  --navbar-height: 4rem;
}