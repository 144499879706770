@import url('base.css');
@import url('themes/light.css');
@import url('variables.css');
@import url('variables-responsive.css');
@import url('reset.css');
@import url('layout.css');
@import url('layout-responsive.css');
@import url('custom.css');
@import url('custom-responsive.css');
@import url('components\/buttons.css');
@import url('components\/accordion.css');
@import url('components\/forms.css');
@import url('components\/forms-responsive.css');
@import url('utilities\/typography.css');
@import url('utilities\/spacing.css');

/* Icons */
/* @import url('https://cdn-uicons.flaticon.com/2.4.0/uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.4.0/uicons-brands/css/uicons-brands.css');
@import url('https://cdn-uicons.flaticon.com/2.4.0/uicons-solid-rounded/css/uicons-solid-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.4.0/uicons-solid-straight/css/uicons-solid-straight.css');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css'); */

/* AOS CSS */
/* @import url('https://unpkg.com/aos@2.3.1/dist/aos.css'); */