.bx-close {
    transform: rotate(45deg);
}


/* --- navbar style --- */
.navbar{
    height: 100%;
    width:var(--navbar-width);
    position: fixed;
    left: 0;
    top: 0;
    background: var(--color-bg-navbar);
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
    box-shadow: 4px 0 8px 0px rgba(0, 0, 0, 0.172); 
    border-right: 0.5px solid var(--color-bg-border);
    z-index: 999;
}


/* nav menu switch */
.navbar .menu-ic,
.navbar .share-ic,
.navbar .close-ic,
.menu,
.history-btn{
    display: none;
}
.top-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 1rem;
    /* height: 58px; */
    min-height: var(--top-navbar-height);
    max-height: var(--top-navbar-height);
    /* border-bottom: 0.1px solid #bfc1d6; */
    border-bottom: 0.5px solid var(--color-bg-border);
    box-shadow: 0 3px 3px -2px #0c2469;
}

/* nav title style */
.navbar .nav-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.main .navbar .web-name{
    color: white;
    font-size: var(--font-size-md);
}
.navbar .nav-title p{
    color: var(--color-primary-accent);
    font-size: 0.45rem;
    font-weight: 300;
    font-style: italic;
}
.navbar .menu-btn,
.navbar .menu-switch-btn{
    background: transparent;
    border: none;
    color: white;
    font-size: 1.65rem;
}



.navbar .menu-btn:hover,
.navbar .menu-switch-btn:hover{
    color: var(--color-primary);
}

.nav-profile{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.25rem 0 0.75rem 0;
    align-items: center;
    justify-content: center;
}
/* nav web logo style */
.nav-profile .web-logo {
    width: 44px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* Add relative positioning to the .web-logo element */
}
.nav-profile .web-logo img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.nav-profile .web-logo::after {
    content: "";
    position: absolute;
    bottom: 2px;
    right: 0px;
    height: 14px;
    width: 14px;
    border: 3px solid var(--color-bg-navbar);
    background-color: rgb(5, 190, 5);
    z-index: 2;
    border-radius: 50%; /* Add border-radius to make it a circle */
}
.nav-profile h2{
    font-size: 0.825rem;
    margin-top: 0.25rem;
    color: white;
}
.nav-profile .view-profile-btn{
    margin-top: 0.45rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 0.6rem;
    /* background-color: var(--color-primary-mid); */
    border: 1px solid var(--color-primary-mid);
    border-radius: var(--border-radius-btn);
    color: var(--color-primary-mid) !important;
}
.nav-profile .view-profile-btn i{
    font-size: 0.7rem;
    font-weight: 500;
}
.nav-profile .view-profile-btn p{
    font-size: 0.6rem;
    display: flex;
    padding-left: 4px;
    font-weight: 500;
    color: var(--color-primary-mid) !important;
}
.nav-profile .view-profile-btn span{
    padding-left: 4px;
    padding-right: 0.25rem;
}
.nav-profile .view-profile-btn:hover{
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
}
.nav-profile .view-profile-btn:hover,
.nav-profile .view-profile-btn:hover p{
    color: var(--color-white) !important;
}



/* nav menu style */
.navbar .nav-menu{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    transform: translateX(0);
}
 .navbar .nav-menu .nav-div{
    width: 100%;
    height: 100%;
    /* padding-left: 1.75rem; */
    overflow: hidden;
    position: relative;
}
.navbar .nav-menu .nav-div .nav-div-item{
    margin-bottom: 0.5rem;
    width: 100%;
}
.navbar .nav-menu .nav-div .nav-div-item.active{
    position: relative;
}

/* nav link style */
.navbar .nav-menu .nav-div .nav-div-item a{
    min-height: 32px;
    border-radius: 10px;
    text-decoration: none;
    padding: 0.2rem 0.75rem;
    color: var(--color-secondary-dark);
    display: flex;
    margin: 0.15rem 1rem;
    align-items: center;
    position: relative;
}
.navbar .nav-menu .nav-div i{
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.navbar .nav-menu .nav-div .nav-div-item.active a{
    /* border: 1px solid var(--color-primary); */
    color: var(--color-primary-accent);
    background-color: #121950;
    background-color: var(--color-primary);
    color: white;
}
.navbar .nav-menu .nav-div .nav-div-item.active::after {
    content: "";
    background-color: var(--color-primary);
    /* background-color: #0d1c8f; */
    width: 7px;
    height: 100%;
    right: -1px;
    top: 50%; 
    transform: translateY(-50%);
    position: absolute;
    z-index: 9;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}
/* nav link hover style*/
.navbar .nav-menu .nav-div .nav-div-item a:hover {
    color: var(--color-primary-accent);
    color: #fff;
}
.navbar .nav-menu .nav-div .nav-div-item a:hover,
.navbar .nav-menu .nav-div .nav-div-item:hover::after {
    background-color: var(--color-primary-accent);
}






/* .navbar .nav-menu .nav-div .nav-div-item.active::after{
    content: "";
    background-color: var(--color-primary-dark);
    width: 8px;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 9;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
} */




/* nav bottom style */
.nav-bottom{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.nav-bottom .download-btns-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.25rem;
}
.nav-bottom .download-btns-container h4{
    color: var(--color-white);
    font-size: 0.6rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
}
.nav-bottom .download-btns-container a{
    font-size: 1.45rem;
    color: var(--color-primary);
    padding: 1rem 0.15rem !important;
}
.nav-bottom .download-btns-container a:hover{
    color: var(--color-white);
}



.play-store-btn,
.app-store-btn{
    display: flex;
    padding: 0.1rem;
    align-items: center;
    text-decoration: none;
    border-radius: 6px;
    width: 126px;
    height: 37px;
    border: 0.5px solid var(--color-gray);
    color: white !important;
}
.app-store-btn{
    color: var(--color-primary-dark) !important;
    background-color: var(--color-primary-mid);
    border: none;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}
.play-store-btn .bxl-play-store,
.app-store-btn .bxl-apple{
    font-size: 1.2rem;
    margin-right: 0;
    padding: 7px 10px;
}
#play-store-btn .play-store-txt,
.app-store-btn .play-store-txt{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-right: 15px;
    
}
#play-store-btn .play-store-txt p,
#play-store-btn .play-store-txt h3{
    color: white;
}
#play-store-btn .play-store-txt p,
.app-store-btn .play-store-txt p{
    font-weight: 400;
    font-size: 0.4rem;
    text-transform: uppercase;
}
#play-store-btn .play-store-txt h3,
.app-store-btn .play-store-txt h3{
    font-weight: 700;
    font-size: 0.65rem;
}
.app-store-btn:hover,
#play-store-btn:hover{
    border-color: transparent;
    background-color: var(--color-primary);
    color: var(--color-white) !important;
}
#play-store-btn:hover,
.app-store-btn:hover,
#play-store-btn:hover p,
.app-store-btn:hover p,
#play-store-btn:hover h3,
.app-store-btn:hover h3{
    color: var(--color-white) !important;
}


/* --- short-navbar STYLE ---*/
.main.short-main{
    padding-left: var(--navbar-short-width);
}
.main .short-navbar{
    width: var(--navbar-short-width);
    padding: 0;
    transition: all 0.4s;
}
.main .short-navbar .top-header{
    justify-content: center;
    /* height: 56px; */
}
.short-navbar .menu-btn{
    position: static;
}
.short-navbar .web-logo{
    margin-top: 0;
}
.main .short-navbar .nav-menu .nav-div{
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main .short-navbar .nav-menu .nav-div .nav-active{
    /* background-color: unset; */
}
.main .short-navbar .nav-menu .nav-div a{

}
.main .short-navbar .nav-menu .nav-div i{
    margin-right: 0;
    height: 1.75rem;
    width: 2.25rem;
    font-size: 0.9rem;
}

.short-navbar .play-store-btn,
 .short-navbar .app-store-btn{
    width: auto;
    padding: 3px;
    height: 40px;
}
.short-navbar .app-store-btn{
    margin-bottom: 0;
}
.short-navbar .nav-menu .nav-div a{
    border-radius: 30px;
}
/* .short-navbar .nav-menu .nav-div .nav-active i{
    background: var(--color-white);
    color: var(--color-primary);
}
.main .short-navbar .nav-menu .nav-div a:hover{
    background-color: transparent;
} */
.short-navbar .nav-bottom{
    position: absolute;
    bottom: 1rem;
}

.nav-short-inactive{
    display: block;
}
.hidden{
    display: none !important;
}


/* --- wrapper style --- */
.wrapper{
    height: 100%;
    width: 100%;
    overflow: hidden;
    
}
.main{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    padding-left: var(--navbar-width);
}


.main-container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}
.hz-navbar{
    /* background-color: var(--color-bg-secondary); */
    margin-left: var(--navbar-width);
    height: 50px;
    width: calc(100% - var(--navbar-width));
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: var(--box-shadow-primary);
    padding: 0 var(--spacing-sec-left-right);
    z-index: 998;
}
.hz-navbar .hz-navbar-title{
    font-size: 1.15rem;
    font-weight: 700;
    color: var(--color-primary-dark);
}




/* --- section style --- */
.content{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    overflow: hidden;
    background-color: var(--color-bg);
}
.section-container{
    width: 100%;
    padding: var(--spacing-sec-left-right);
    background-color: var(--color-bg);
}
.section-head{
    margin-bottom: 2rem;
 }
.section-head-div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 30px;
}
.sec-title{
    font-size: var(--font-size-lg);
    font-weight: 700;
}
.section-des{
    flex: 1;
    font-size: var(--font-size-sm);
    font-weight: 300;
}
.section-head .section-head-img-container{
    width: 100%;
    display: flex;
    align-items: center;
}
.section-head .section-head-img-container img{
    width: 430px;
    margin: 1rem 0;
}



/* --- footer style --- */
.footer{
    width: 100%;
    padding: 6rem var(--spacing-sec-left-right) 4rem var(--spacing-sec-left-right);
    display: flex;
    background-color: var(--color-footer-bg);
}

/* footer container */
.footer .left-con{
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.footer .right-con{
    width: 35%;
    display: flex;
    justify-content: end;
    /* align-items: center; */
    flex-direction: column;
}
.footer .left-con,
.footer .right-con{
    
}

/* footer element style */
.footer .left-con .logo-div{
    max-width: 300px;
}
.footer .left-con .logo-div h2{
    font-size: var(--font-size-md);
    font-weight: 600;
    color: var(--color-white);
}
.footer .left-con .logo-div h1{
    font-size: var(--font-size-sm);
    color: var(--color-primary);
    font-weight: 300;
    margin-bottom: 8px;
    font-style: italic;
}
.footer .left-con .logo-div p{
    font-size: var(--font-size-sm);
    font-weight: 300;
    text-align: justify;
    color: var(--color-white);
}

.footer .right-con a{
    text-decoration: none;
    color: var(--color-white);
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px 0;
    font-weight: 500;
}
.footer .right-con a i{
    font-size: 1rem;
    margin-right: 6px;
}
.footer .right-con a:hover{
    background-color: transparent;
    color: var(--color-primary);
}
.footer .right-con a:hover i{
    transform: rotate(90deg);
    transition: transform 0.2s;
}

/* footer copyright text */
.copyright-txt{
    color: #a6a6a6;
    font-size: 0.7rem;
    font-weight: 300;
    width: 100%;
    margin-top: 0.5rem;
    opacity: 0.5;
}


