/*---------------------------------------------*/
*{
  font-family:  var(--font-primary);
}
input {
	outline: none;
	border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #c6c6c6;}
input:-moz-placeholder { color: #c6c6c6;}
input::-moz-placeholder { color: #c6c6c6;}
input:-ms-input-placeholder { color: #c6c6c6;}

textarea::-webkit-input-placeholder { color: #c6c6c6;}
textarea:-moz-placeholder { color: #c6c6c6;}
textarea::-moz-placeholder { color: #c6c6c6;}
textarea:-ms-input-placeholder { color: #c6c6c6;}


label {
  display: block;
  margin: 0;
}







/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    height: 60px;
    position: relative;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    margin-bottom: 1rem;
    text-align: start;
  }
  .otp-div{
      margin-top: 0.65rem;
  }
  .otp-div .alert-div p {
      font-size: var(--font-size-xs);
      color: rgb(5, 147, 5);
  }
  .otp-div,
  .new-pw-div{
      display: none;
  }
  
  .label-input100 {
    font-size: var(--font-size-sm);
    color: #c6c6c6;
    line-height: 1.2;
    display: block;
    position: absolute;
    pointer-events: none;
    width: 100%;
    padding-left: 1rem;
    left: 0;
    top: 20px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  .input100 {
    display: block;
    width: 100%;
    background: transparent;
    color: var(--color-text-primary);
    line-height: 1;
    padding: 0 1rem;
    font-size: var(--font-size-sm);
  }
  
  input.input100 {
    height: 100%;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  /*---------------------------------------------*/
  
  .focus-input100 {
    position: absolute;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    pointer-events: none;
    border: 2px solid var(--color-primary);
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  
    -webkit-transform: scaleX(1.1) scaleY(1.3);
    -moz-transform: scaleX(1.1) scaleY(1.3);
    -ms-transform: scaleX(1.1) scaleY(1.3);
    -o-transform: scaleX(1.1) scaleY(1.3);
    transform: scaleX(1.1) scaleY(1.3);
  }
  
  .input100:focus + .focus-input100 {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  
  .eff-focus-selection {
    visibility: visible;
    opacity: 1;
  
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  
  .input100:focus {
    height: 48px;
  }
  
  .input100:focus + .focus-input100 + .label-input100 {
    top: 8px;
    font-size: var(--font-size-xs);
  }
  
  .has-val {
    height: 48px !important;
  }
  
  .has-val + .focus-input100 + .label-input100 {
      top: 8px;
      font-size: var(--font-size-xs);
  }
  
  /*==================================================================
  [ Restyle Checkbox ]*/
  .checkbox-div-parent{
      margin-top: 0.8rem;
      padding: 0 0.5rem;
  }
  .checkbox-div{
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .checkbox-div label{
      font-size: var(--font-size-sm);
      margin-left: 6px;
      color: var(--color-text-primary);
  }
  .forgot-btn{
    font-size: var(--font-size-sm);
  }
  
  .label-checkbox100 {
    font-size: var(--font-size-sm);
    color: #c6c6c6;
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
  }
  
  .input-checkbox100:checked + .label-checkbox100::before {
    color: #6675df;
  }
  
  .d-flex-1{
      display: flex;
      justify-content: space-between;
  }
  
  /*------------------------------------------------------------------
  [ Button ]*/
  .container-login100-form-btn {
    width: 100%;
    max-width: 24rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0.9rem;
  }
  
  .login100-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 44px;
    border-radius: var(--border-radius-lg);
    background: var(--color-primary);
    font-size: var(--font-size-sm);
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 1px;
      font-weight: 500;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  .login100-form-btn:hover {
    background: var(--color-primary-dark);
  }
  
  .login50-form-outline {
      width: calc(50% - 4px);
      height: 44px;
      background-color: transparent;
      border-radius: var(--border-radius-lg);
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;
      letter-spacing: 1px;
      font-weight: 500;
      font-size: var(--font-size-sm);
  }
  
  .login50-form-outline i{
      font-size: var(--font-size-md);
  }
  
  .login50-form-outline span{
      flex: 1;
  }
  
  .container-login100-form-btn .login50-form-outline:nth-child(1){
      margin-right: 4px;
      border: 1px solid #b72323;
      color: #b72323;
  }
  .container-login100-form-btn .login50-form-outline:nth-child(1):hover {
      color: white;
      background: #b72323;
  }
  .container-login100-form-btn .login50-form-outline:nth-child(2){
      margin-left: 4px;
      border: 1px solid #201f34;
      color: #201f34;
  } 
  .container-login100-form-btn .login50-form-outline:nth-child(2):hover{
      color: white;
      background-color: #201f34;
  }
  
  .onlytxt{
      font-size: var(--font-size-xs);
      margin-top: 0.9rem;
      color: #9999;
      text-align: center;
  }





  /* --- Contact form style --- */
.contact-content form .form-div{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.contact-content .contact-btn-div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-content .contact-btn-div button{
  width: 30%;
}
.contact-content form .left,
.contact-content form .right{
  width: 47%;
  
}
.contact-content form .left{
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  margin-bottom: 1rem;
}

.contact-content form .right textarea{
  width: 100%;
  height: 96%;
  margin-top: 1rem;
  padding: 0.9rem 1rem;
  border: none;
  outline: none;
  resize: none;
  min-height: 224px;
}
.contact-content form .left .input-div{
  padding: 0;
  display: flex;
  min-height: unset;
  padding: 0.9rem 1rem;
  margin-bottom: 2rem;
}

.contact-content form .input-div input,
.contact-content form .input-div select{
  border: none;
  outline: none;
 height: 100%;
 flex: 1;
 font-size: var(--font-size-sm);
 font-weight: 400;
 margin-right: 1rem;
}
.contact-content form .input-div select{
  margin-right: 0;
}
.contact-content form .input-div input::placeholder,
.contact-content form  textarea::placeholder{
  color: #cccccc;
}
.contact-content form label{
  font-weight: 500;
  text-transform: uppercase;
  font-size: var(--font-size-sm);
  margin-bottom: 10px;
}
.contact-content form select{
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  margin-right: 0.75rem;
}
.contact-content form select option{
 /* padding: 1rem; */
}
.contact-content .wrap-input100{
  margin-bottom: 1.25rem !important;
}

.contact-content .right .conatct-textarea{
  height: 224px;
  margin-bottom: 0;
}