@media only screen and (max-width: 1025px) and (orientation: portrait){
  .login50-form-outline,
  .login100-form-btn{
    height: 56px;
  }
  .wrap-input100{
    height: 75px;
  }
  .label-input100{
    top: 28px;
  }
}
@media only screen and (max-width: 991px){

}

@media only screen and (max-width: 768px){
  .login50-form-outline,
  .login100-form-btn{
    height: 50px;
  }
  .wrap-input100{
    height: 60px;
  }
  .label-input100{
    top: 22px;
  }
}

@media only screen and (max-width: 480px){
  .contact-content form .form-div{
    flex-direction: column;
  }
  .contact-content form .left, .contact-content form .right{
    width: 100%;
  }
  .contact-content{
    padding-bottom: 3rem;
  }
}

@media only screen and (max-width: 390px){

}
