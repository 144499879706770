/* reset.css */
body{
  /* transform: scale(0.9);
  transform-origin: 0 0; */
}

/* Remove default margin and padding */
* {
    margin: 0;
    padding: 0;
  }
  
  /* Set default box-sizing to border-box */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  /* Additional reset styles... */
  *{
    transition: 0.2s;
    scroll-behavior: smooth;
  }

  input:-webkit-autofill {
    background-color: #e0f7fa !important; /* Change to your desired background color */
    -webkit-text-fill-color: #000 !important; /* Ensure text color is readable */
    transition: background-color 5000s ease-in-out !important; /* Smooth transition */
}

