/* --- Accordian styles --- */
.accordion{
    /* background-color: #d5dcf1c0; */
    color: var(--color-text);
    cursor: pointer;
    font-size: var(--font-size-sm);
    width: 100%;
    padding: 0.75rem 0;
    border: none;
    outline: none;
    transition: 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    text-align: left;
    font-weight: 400;
}

/* Accrodian icon styles */
.accordion i:first-of-type{
    background-color: var(--color-primary-dark);
    color: var(--color-white);
    border-radius: 30px;
    padding: 0.4rem;
    margin-right: 0.6rem;
    font-size: var(--font-size-sm);
    font-weight: 500;
}
.accordion i:last-child{
    margin-top: -0.35rem;
    font-size: 1.55rem;
    color: var(--color-text);
    font-weight: 700;
    padding: 0rem 0.75rem;
}
.accordion span{
    flex: 1;
    font-weight: 500;
    font-size: 0.9rem;
}
.bx-plus:before {
    content: "\002B"; /* Unicode for plus sign */
}
.bx-minus:before {
    content: "\2212"; /* Unicode for minus sign */
}

.panel {
    color: var(--color-text);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    padding: 0 2.25rem 0rem 2.25rem;
    transition: max-height 0.7s ease-in-out, opacity 0.5s ease-in-out, padding 0.1 ease-in-out;
    position: relative; /* Needed for pseudo-element positioning */
}

.panel.show {
    max-height: 500px; /* Adjust based on content */
    opacity: 1;
    padding: 0 2.25rem 0.75rem 2.25rem; /* Constant padding */
}

.panel::before {
    content: '';
    display: block;
    height: 0.75rem; /* Additional padding */
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}




  
.panel p{
    color: var(--color-text);
    font-size: var(--font-size-sm);
    font-weight: 300;
    line-height: 1.5;
}

.accordion.active .icon {
    transform: rotate(180deg);
    transition: transform 0.2s;
}

.accordion-container .card{
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: auto;
    background-color: var(--color-card-bg);
}