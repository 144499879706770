
body {
  font-family: var(--font-primary);
  font-size: var(--font-size-sm);
  color: var(--color-text);
  overflow: hidden;
  overflow-y: auto;
}
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-primary);
  font-weight: 700;
  color: var(--color-text-primary);
}
p{
  font-weight: 300;
  color: var(--color-text-primary);
}
a{
  text-decoration: none;
}