a:visited{
  color: var(--color-primary);
}
/* --- card style --- */
.card,
.default-card{
    background-color: var(--color-card-bg);
    box-shadow: var(--box-shadow-primary);
    border-radius: var(--border-radius);
    transition: all 0.2s ease-in;
    cursor: pointer;
}
.card{
    display: flex;
    align-items: center;
    padding: 0rem .7rem; 
    min-height: 3.8rem;
}

.contains-loader {
    height: 80px;
    width: 90px;
    background-color: var(--color-card-bg);
    box-shadow: var(--box-shadow-primary);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: var(--border-radius);
}
.contains-loader i {
    font-size: 2rem;
    animation: spin 2s linear infinite;
    color: var(---color-text-primary) !important; /* Initial color */
}
.contains-loader p{
    margin-top: 4px;
    font-size: 10px;
}

@keyframes spin {
    from { 
        transform: rotate(0deg); 
    }
    to { 
        transform: rotate(360deg);  
    }
}

/* --- loading bar style --- */
#loading-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    backdrop-filter: blur(3px);
}
#loading-dialog .default-card{
    padding: 1rem;
}
.loading-div-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.loading-image {
    width: 56px;
    position: relative;
    z-index: 1;
}
.loading-cover {
    position: absolute;
    top: 0;
    width: 200px;
    height: 200px;
    background-color: var(--color-bg); 
    animation: reveal 3s ease-out infinite;
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    z-index: 2; 
}

.loading-spinner {
    position: absolute;
    border: 4px solid #e9e9e9;
    border-top: 4px solid var(--color-primary);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    z-index: 3;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@keyframes reveal {
    0% { 
        height: 100%; 
        transform: rotate(60deg);
    }
    100% { 
        height: 0; 
    }
}



/* --- alert dialog style --- */
.alert-dialog {
    position: fixed;
    top: 100%; /* Initially off screen */
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg-dialog);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(3px);
    transition: top 0.4s ease; /* Transition for top property */
}

.alert-dialog.show {
    top: 0; /* Slides into view */
}

.alert-dialog .dialog-container{
    background-color: var(--color-card-bg);
    border-radius: 10px;
    display: flex;
    max-width: 370px;
    max-height: 350px;
    padding: 20px 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: relative;
}
.alert-dialog .dialog-container .lottie-container{
    width: 120px;
    height: 120px;
    margin-top: -1rem;
}
.alert-dialog .dialog-container .dialog-body{
    display: flex;
    overflow: hidden;
}
.alert-dialog .dialog-container .close-btn{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.5rem;
    padding: 0.5rem;
}

.alert-dialog .dialog-container .dialog-title h2{
    font-size: 1.2rem;
    margin-bottom: 8px;
    color: var(--color-text-primary);
    font-weight: 600;
}
.alert-dialog .dialog-container .dialog-body p{
    font-size: 0.825rem;
    color: #545454;
    margin-bottom: 1.75rem;
}
.alert-dialog .dialog-container .dialog-footer{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}


.alert-dialog .dialog-container .dialog-footer .dark-btn{
    background-color: var(--color-primary);
    color: var(--color-white);
}
.alert-dialog .dialog-container .dialog-footer .dark-btn:hover{
    background-color: var(--color-primary-dark);
}

/* alert text style */
#alertText {
    position: fixed;
    bottom: 10rem;
    left: 70%;
    transform: translateX(-50%);
    background-color: #333;
    color: var(--color-white);
    padding: 10px 20px;
    border-radius: 30px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 99999999;
}




/* --- default dialog style ---  */
.default-dialog {
    position: fixed;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg-dialog);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(3px);
    transition: top 0.4s ease; /* Transition for top property */
}

.default-dialog.show {
    top: 0; /* Slides into view */
}

.default-dialog .default-dialog-container{
    width: 430px;
    background-color: var(--color-card-bg);
    border-radius: 10px;
    display: flex;
    max-width: 430px;
    max-height: 510px;
    padding: 2rem;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    /* flex-wrap: wrap; */
    text-align: center;
    position: relative;
    padding: 1.5rem;
}
.preview-dialog .default-dialog-container{
    width: 800px;
    height: 500px;
    max-width: 800px;
    flex-direction: row;
    overflow: hidden;
    padding: 0 0.5rem;
}
.preview-dialog .default-dialog-container .inner-box{
    width: 50%;
    height: 94%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0.5rem;
    border-radius: 6px;
}
.preview-dialog .default-dialog-container .inner-box:nth-child(2){
    padding: 0.25rem 1rem !important;

}
.preview-dialog .default-dialog-container .preview-canva-img{
    width: 100%;
    border-radius: 6px;
    border: 1.25px solid var(--color-primary-light);
}
.file-input-container {
    position: relative;
    width: 90%;
}
  
.file-input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
  
.file-input-label {
    width: 100%;
    display: inline-block;
    background-color: var(--color-gray); /* Change color as per your preference */
    color: var(--color-text-primary);
    padding: 30px 20px;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 0.9rem;
}
  
.file-input-label:hover {
    background-color: #0056b3; /* Change color as per your preference */
}

.preview-dialog .default-dialog-container .inner-box .btn-div{
    margin-top: 2rem;
    display: flex;
    gap: 0.5rem;
}
 
.default-dialog .default-dialog-container .dialog-container-box{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 1rem;
    padding-top: 0.5rem;
    width: 100%;
}
.default-dialog .default-dialog-container .dialog-container-box .profile-icon-demo{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid var(---color-text-primary);
    
}
.default-dialog-container .dialog-container-box input{
    margin-left: 10px;
}
.default-dialog-container .dialog-container-box button {
    border-radius: var(--border-radius-lg);
    background-color: var(--color-primary-dark);
    font-size: 0.75rem;
    color: var(--color-white);
    padding: 0.35rem 0.75rem;
    margin-left: 0.75rem;
}

.default-dialog-container .dialog-form{
    flex: 1;
    width: 100%;
}
.default-dialog-container .dialog-form h2{
    width: 100%;
    text-align: left;
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
}
.default-dialog-container .dialog-form .dialog-form-btn-div{
    display: flex;
    gap: 10px;
    margin-top: 1rem;
}
.default-dialog-container .dialog-form .dialog-form-btn-div button{
    flex: 1;
}




/* --- custom dialog style --- */
#custom_dialog{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg-dialog);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(3px);
    display: none;
}
.custom_dialog-div{
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    display: flex;
    max-width: 300px;
    padding: 20px 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.custom_dialog-div h3{
    margin: 15px 0;
}
.custom_dialog-div .button{
    width: 100%;
    height: 2.65rem;
    padding: 0px 2rem;
    margin-top: 1.2rem;
    background-color: var(--color-bg-secondary);
    border-radius: 30px;
    color: var(--color-primary);
    outline: none;
    border: none;
    font-weight: 500;
    font-size: 1rem;

}
.custom_dialog-div .button:hover{
    background-color: var(--color-primary);
    color: #fff;
}
.custom_dialog-div .close-ic{
    background-color: #1d2224c6;
    font-size: 1.3rem;
    padding: 5px;
    position: absolute;
    border-radius: 50px;
    bottom: 150px;
    color: var(--color-white);
}




/* --- Preloader style --- */

.small-preloader-dialog .preloader-dialog-div {
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    padding: 1.25rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    box-shadow: var(--box-shadow-primary);
}
.small-preloader-dialog .preloader-dialog-div i{
    font-size: 2rem;
    font-weight: 500;
    animation: spin 2s linear infinite;
}
.small-preloader-dialog .preloader-dialog-div p{
    font-size: var(--font-size-sm);
    margin-top: 0.5rem;
}

.small-preloader-dialog,
#book-name-dialog,
#chapter-dialog,
#language-dialog,
#translation-dialog{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(2px);
    display: none;
}
.small-preloader-dialog{
    display: flex;
}





/* --- Bible dialog style --- */
.dialog-book-div,
.dialog-chapter-div,
.language-dialog-div,
.translation-dialog-div{
    background-color: var(--color-bg);
    border-radius: 10px;
    display: flex;
    padding: 0px 20px 30px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 92vh;
    min-width: 45vw;
    justify-content: flex-start;
    box-shadow: var(--box-shadow-primary);
}
.dialog-title-div{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0.8rem 0.75rem;
}
.dialog-title-div button{
    border-radius: 3rem;
    border: none;
    color: var(--color-text-primary);
    padding: 0.35rem 0.35rem 2px 0.35rem;
}
.dialog-title-div button:hover{
    color: var(--color-primary);
}
.dialog-title-div button i{
    font-size: 1.75rem;
}

.dialog-title-div h3{
    color: var(--color-text-primary);
    font-size: 1.25rem;
    flex: 1;
    text-align: start;
}
.dialog-chapter-div h3{
    text-align: center !important;
}

.dialog-container{
    max-width: 85vw;
    max-height: 100%;
    margin: 0 auto;
    overflow: hidden;
    width: 85vw;
    height: 100%;
}
.translation-dialog-div .dialog-container,
.language-dialog-div .dialog-container{
    width: 100%;
    height: 100%;
}
.dialog-container-list{
    max-height: 100%;
    width: 100%;
    display: grid;
    grid-gap: 15px;
    padding: 5px 10px;
    overflow-y: auto;
}

.dialog-book-div .book-name-list{
    grid-template-columns: repeat(auto-fit, minmax(13vw, 3fr));
}

.dialog-book-div .book-name-list .book-name{
    font-size: var(--font-size-sm);
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.7rem;
    color: var(--color-text-primary);
    background-color: var(--color-card-bg);
    box-shadow: var(--box-shadow-primary);
    border-radius: var(--border-radius);
    transition: all 0.2s ease-in;
    cursor: pointer;
}


.dialog-chapter-div .chapter-list{
    grid-template-columns: repeat(auto-fit, minmax(6vw, 3fr));
}
.dialog-chapter-div .chapter-list .chapter{
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.7rem;
    color: var(--color-text-primary);
    max-width: 100%;
    background-color: var(--color-card-bg);
    box-shadow: var(--box-shadow-primary);
    border-radius: var(--border-radius);
    transition: all 0.2s ease-in;
    cursor: pointer;
}
.language-dialog-list {
    grid-template-columns: repeat(auto-fit, minmax(20vw, 3fr));
}

.language{
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.7rem;
    color: var(--color-text-primary);
    max-width: 100%;
    background-color: var(--color-card-bg);
    box-shadow: var(--box-shadow-primary);
    border-radius: var(--border-radius);
    font-size: var(--font-size-sm);
    transition: all 0.2s ease-in;
    cursor: pointer;
}

.translation-dialog-list .translation{
    display: flex;
    padding: 1rem;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    color: var(--color-text-primary);
    max-width: 100%;
    cursor: pointer;
    position: relative;
    background-color: var(--color-card-bg);
    box-shadow: var(--box-shadow-primary);
    border-radius: var(--border-radius);
    transition: all 0.2s ease-in;
    cursor: pointer;
}
.translation-dialog-list .translation h4{
    font-size: var(--font-size-sm);
}
.translation-dialog-list .translation p{
    font-size: var(--font-size-sm);
    opacity: 0.75;
}
.translation-dialog-list .translation a{
    position: absolute;
    right: 0;
    margin-right: 1rem;
    text-decoration: none;
    color: var(--color-primary);
}
.dialog-container-list .book-name:hover,
.dialog-container-list .chapter:hover,
.dialog-container-list .language:hover,
.translation-dialog-list .translation:hover{
    background-color: var(--color-primary-mid-dark);
    color: #fff;
}

.translation-dialog-list .translation:hover h4,
.translation-dialog-list .translation:hover p,
.translation-dialog-list .translation:hover a{
    color: #fff;
}



/* --- typing indicator style --- */
.typing-indicator{
    will-change: transform;
    width: fit-content;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 1rem 0;
}
.typing-indicator .typing-div{
    padding: 0.8rem;
    background: var(--color-white);
    border-radius: var(--border-radius-chat-bg);
    display: flex;
    box-shadow: var(--box-shadow-primary);
}
.typing-indicator img{
    width: var(--chat-icon-size);
    height: var(--chat-icon-size);
    margin: 0 7px;
    border-radius: 50%;
}

.typing-indicator span{
    height: 0.485rem;
    width: 0.485rem;
    margin: 0 3px;
    background-color: var(--color-text-primary);
    display: block;
    border-radius: 50%;
    opacity: 0.2;
}
.typing-indicator span:nth-child(1){
    animation: 1s blink infinite 0.25s;
}
.typing-indicator span:nth-child(2){
    animation: 1s blink infinite 0.50s;
}
.typing-indicator span:nth-child(3){
    animation: 1s blink infinite 0.75s;
}

/* typing indiacator animation */
.typing-indicator span:nth-child(4){
    animation: 1s blink infinite 1s;
}
@keyframes blink {
    50%{
        opacity: 1;
    }
}



/*  */
.light-bold-title{
    font-weight: 300;
    color: var(--color-text-primary);
    font-size: var(--font-size-lg);
}
.light-bold-title span{
    font-weight: 600;
}




 /* --- cookies styles --- */
 .cookies-permission{
    display: none;
    position: fixed;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1001;
    cursor: pointer;
    background-color: var(--color-bg-dialog);
    transition: left 0.2s ease-in-out;
}

.cookies-permission .cookies-div{
    background-color: var(--color-bg);
    position: absolute;
    align-items: start;
    justify-content: start;
    left: 35%;
    bottom: 2rem;
    padding: 20px;
    display: flex;
    width: 300px;
    flex-direction: column;
    border-radius: 10px;
    transform: translate(-50%, 0);
}
.cookies-permission.show{
    left: 0%;
    display: flex;
}

.cookies-permission i{
    font-size: 4rem;
    color: var(--color-text-primary);
}
.cookies-permission .app-content{
    display: flex;
    flex-direction: column;
}
.cookies-permission h1{
    font-size: 1.1rem;
    font-weight: 600;
    text-align: start;
    color: var(--color-text-primary);
}
.cookies-permission p{
    font-size: 0.95rem;
    margin-top: 5px;
    text-align: justify;
}
.cookies-permission a{
    text-align: right !important;
    font-size: 0.9rem;
    text-decoration: none;
    color: var(--color-primary);
    text-transform: lowercase;
}
.cookies-permission .app-content-btn{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
}
.cookies-permission .btn{
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: 0.5rem;
}

.app-store-dialog{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg-dialog);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(3px);
}
.app-store-dialog.show{
    display: flex;
}

.app-store-dialog-div{
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 500px;
    width: 470px;
    justify-content: flex-start;
}
.app-store-dialog-div img{
    height: 380px;
}
.app-store-dialog-div .dialog-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
}
.app-store-dialog-div .dialog-container #play-store-btn{
    padding: 3px 0;
}
.app-store-dialog-div .app-play-store-btn{
    display: flex;
    border: none;
    background-color: var(--color-primary) !important;
    padding: 3px 0;
    width: 12vw;
    align-items: center;
    text-decoration: none;
    color: var(--color-white) !important;
    border-radius: 6px;
    margin: 0 10px;
}
.app-store-dialog-div  .first{
    background-color: #dbdbdb !important;
    color: #222 !important;
}
.app-store-dialog-div .app-play-store-btn .bxl-play-store{
    color: var(--color-white) !important;
    font-size: 1.9rem;
    margin-right: 0;
    padding: 7px;
}
.app-store-dialog-div .app-play-store-btn .bx-globe{
    color: #222;
    font-size: 1.85rem;
    margin-right: 0;
    padding: 8px;
}
.app-store-dialog-div .app-play-store-btn .play-store-txt{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-right: 15px;
}
.app-store-dialog-div .app-play-store-btn .play-store-txt p{
    font-weight: 400;
    font-size: 0.6rem;
    text-transform: capitalize;
}
.app-store-dialog-div .app-play-store-btn .play-store-txt h3{
    font-weight: 600;
    font-size: 0.95rem;
}


.app-store-dialog-div .app-play-store-btn:hover{
    color: var(--color-white);
    background-color: var(--color-primary);
}
.app-store-dialog-div .app-play-store-btn .bxl-play-store:hover{
    color: var(--color-white) !important;
}

.app-store-dialog-div .first:hover{
    color: var(--color-white);
    background-color: #222;
}
.app-store-dialog-div .first .bx-globe:hover{
    color: var(--color-white) !important;
}









