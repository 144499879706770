:root{
    --color-primary:#3B53ED;
    --color-primary-mid: #89b2ff;
    --color-primary-light:#cadeff;
    --color-primary-dark: #081330;
    --color-primary-mid-dark:#223b79;
    --color-primary-accent: #0022ff;
    --color-secondary:#a5b6be;
    --color-secondary-dark:#98a2a8;
    --color-secondary-light: #e9ecef;
    --color-accent: #28a745;
    --color-accent-dark: #218838;
    --color-accent-light: #51cf66;
    --color-bg: #fff;
    --color-bg-dark: #343a40;
    --color-bg-secondary:#f6f9ff;
    --color-bg-dialog:#0a183b5f;
    --color-bg-border:#08133014;
    --color-card-bg:#FFFFFF;
    --color-footer-bg: #081330;
    --color-bg-navbar:#081330;
    --color-bg-ai: #FFFFFF;
    --color-text: #333;
    --color-text-light: #fff;
    --color-text-primary: #081330;
    --color-text-secondary:#222222;
    --color-text-gray-300:#979797;
    --color-text-hint:rgba(99, 99, 99, 0.265);
    --color-text-chat-ai: #000;
    --color-text-chat-user: #ffffff;
    --color-text-chat:#dedefc;
    --color-white:#FFFFFF;
    --color-gray: #d3d3d3;
    --box-shadow-primary-color: rgba(0, 0, 0, 0.1);
    --box-shadow-secondary-color: rgba(0, 0, 0, 0.2);
}
