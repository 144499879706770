@media only screen and (max-width: 1025px){
    .translation-dialog-list .translation{
        padding: 1.15rem;
    }
    .dialog-book-div .book-name-list .book-name,
    .language{
        height: 3.5rem;
    }
    .preview-dialog .default-dialog-container{
        max-width: 900px;
        width: 900px;
        height: 580px;
        max-height: 550px;
    }
}

@media only screen and (max-width: 769px){
    .dialog-book-div .book-name-list{
        grid-template-columns: repeat(auto-fit, minmax(25vw, 3fr));
    }
    .dialog-chapter-div .chapter-list{
        grid-template-columns: repeat(auto-fit, minmax(15vw, 3fr));
    }
    .language-dialog-list{
        grid-template-columns: repeat(auto-fit, minmax(30vw, 3fr));
    }

    .default-dialog .default-dialog-container{
        width: 380px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        max-height: 700px !important;
    }
    .preview-dialog .default-dialog-container .inner-box{
        width: 100%;
    }
    .preview-dialog .default-dialog-container .inner-box-right{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .file-input-container{
        width: 100%;
    }
    .preview-dialog .default-dialog-container .inner-box:nth-child(2){
        padding: 1rem !important;
        padding-bottom: 2rem !important;
    }
}
@media only screen and (max-width: 480px){
    .default-dialog .default-dialog-container{
        width: 380px;
        
    }
    .preview-dialog .default-dialog-container .inner-box{
        width: 100%;
    }
    .file-input-container{
        width: 100%;
    }
}