button{
    outline: none !important;
    border: none;
    background-color: transparent;
}


/* --- Button styles --- */
.btn,
.a-btn,
.btn-outline,
.a-btn-outline{
    width: fit-content;
    padding: 0.5rem 1.25rem;
    background-color: var( --color-primary);
    border: 1.35px solid var( --color-primary);
    border-radius: var(--border-radius-btn);
    color: var(--color-white);
    font-weight: 500;
    font-size: var(--font-size-sm);
    text-decoration: none;
    text-align: center;
    transition: 0.3s ease-in-out;
}
.btn:visited,
.a-btn:visited{
    color: var(--color-white);
}
.btn-outline:visited,
.a-btn-outline:visited{
    color: var(--color-text-primary);
}
.a-btn:hover,
.btn:hover{
    background-color: var(--color-primary-dark);
    border: 1.35px solid var(--color-primary-dark);
}
.btn-outline,
.a-btn-outline{
    border: 1.35px solid var(--color-text-primary);
    background-color: transparent;
    color: var(--color-text-primary);
}
.btn-outline:hover,
.a-btn-outline:hover{
    border: 1.35px solid var(--color-primary-dark);
    background-color: var(--color-primary-dark);
    color: #fff;
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .btn, .a-btn, .btn-outline, .a-btn-outline{
        padding: 0.75rem 1.5rem;
    }
}
